// tslint:disable-next-line: no-implicit-dependencies
import { Middleware } from "@nuxt/types";
// tslint:disable-next-line: no-implicit-dependencies
import { Store } from "vuex";
// tslint:disable-next-line: no-implicit-dependencies
import cookie from "cookie";

import Cookie from "universal-cookie";

import { RootState } from "~/store";
import ViewerProfileQuery from "@/apollo/ViewerProfileQuery.graphql";
import PublicBrowsingAgentUserQuery from "@/apollo/PublicBrowsingAgentUserQuery.graphql";
import AssignBrowsingSessionMutation from "@/apollo/AssignBrowsingSessionMutation.graphql";

import { Viewer, Viewer_viewer as ViewerNode } from "@/gql-typings/Viewer";
import { AssignBrowsingSessionVariables, AssignBrowsingSession } from "@/gql-typings/AssignBrowsingSession";
import { PublicBrowsingAgentUser } from "@/gql-typings/PublicBrowsingAgentUser";
import { JWTPayload, BrowsingSessionPayload } from "~/misc/interfaces";
import { CookieAttributes } from "@nuxtjs/apollo";

const isBrowsingSessionPayload = (payload: JWTPayload): payload is BrowsingSessionPayload => Boolean((payload as BrowsingSessionPayload).session_id);

const decodeToken = (token: string): JWTPayload => {
	const [, encodedPayload] = token.split(".");
	const JSONpayload = process.server ? Buffer.from(encodedPayload, "base64").toString("utf8") : atob(encodedPayload);
	const decodedPayload = JSON.parse(JSONpayload);

	return decodedPayload;
};

// browsing tokens should be set for a long time, like 3 months
const BROWSING_SESSION_TOKEN_EXPIRY_DAYS = 90;
// the user tokens should, be limited to 7 days.
const USER_TOKEN_EXPIRY_DAYS = 7;

const BROWSING_SESSION_TOKEN_EXPIRY_SECONDS = 60 * 60 * 24 * BROWSING_SESSION_TOKEN_EXPIRY_DAYS;

const checkAuthMiddleware: Middleware = async (context) => {
	const { req, app, store: _store, query, redirect, route, params, res, $config, $sentry, $sentryReady } = context;

	// If nuxt generate, pass this middleware
	if (process.server && !req) return;

	const target_url = process.server ? `${$config.HOST_WEB}${route.fullPath}` : document.location.href;

	const store = _store as Store<RootState>;

	const matomo = {};

	if (route.meta) {
		route.meta.matomo = matomo;
	}

	if (!app.apolloProvider) {
		throw new Error("Apollo provider is missing");
	}

	// get JWT from cookie.
	// this can be for a viewer, or simply a browsing session
	let token = app.$apolloHelpers.getToken();
	const { defaultClient: client } = app.apolloProvider;

	const secure = $config.HOST_WEB.startsWith("https");

	const baseApolloCookieAttributes: Partial<CookieAttributes> = {
		secure,
		path: "/",
		sameSite: "strict",
	};

	// tslint:disable-next-line: no-shadowed-variable
	const setUserOnSentry = (viewer: ViewerNode | null) => {
		const ip_address = (process.server ? (req.headers['x-forwarded-for']?.toString() || req.connection.remoteAddress) : null) || "{{auto}}";

		$sentryReady?.().then($sentry => {
			$sentry.setUser(viewer ? {
				id: viewer.oid,
				type: viewer.__typename,
				ip_address,
			} : null);
		});
	};

	// tslint:disable-next-line: no-shadowed-variable
	const setUserAnalytics = (viewer: ViewerNode | null) => {
		if (process.client) {
			return;// only run this on SSR; analytics user is set on client via a plugin
		}

		setUserOnSentry(viewer);

		// NOTE: route.meta.matomo doesn't work on SSR
		Object.assign(matomo, {
			userId: ["setUserId", viewer ? viewer.oid : ""],
			userTypeVar: ["setCustomVariable", 1, "UserType", viewer ? viewer.__typename : ""],
		});
	};

	const cookieOptions: cookie.CookieSerializeOptions = {
		secure,
		expires: new Date(Date.now() + (BROWSING_SESSION_TOKEN_EXPIRY_SECONDS * 1000)),
		path: "/",
		sameSite: "strict"
	};

	const getBrowsingSession = async () => {
		// TODO: should this only run on the server? i.e. only init a browsing session on first run?

		let result;

		try {
			result = await client.mutate<AssignBrowsingSession, AssignBrowsingSessionVariables>({
				mutation: AssignBrowsingSessionMutation,
				variables: {
					input: {
						target: target_url,
					},
				},
			});

			if (result.errors) {
				const [error] = result.errors;

				throw error;
			}

			// TODO: what happens if this fails??
		} catch (e) {
			throw e;
		}

		if (result.data) {
			const {jwt} = result.data;

			const payload = decodeToken(jwt);

			if (!isBrowsingSessionPayload(payload)) {
				throw new Error("Unexpected session payload");
			}

			await store.dispatch("setBrowsingSessionId", payload.session_id);

			// set the token in cookies
			// TODO: find a more re-usable way to accomplish this
			if (process.server) {
				// special logic on the server
				const existingCookieHeaderValue = req.headers.cookie;

				if (existingCookieHeaderValue) {
					// must do some cookie splicing, as cookie is changed mid-request
					const replacedCookieHeaderValue = existingCookieHeaderValue.replace(/(dot-love-api-token=)[^;]+(;)?/, `$1${jwt}$2;`);

					req.headers.cookie = replacedCookieHeaderValue;
				}

				const jwtCookieHeaderValue = cookie.serialize("dot-love-api-token", jwt, cookieOptions);

				const bSessionCookieHeaderValue = cookie.serialize("b-session", jwt, cookieOptions);

				if (!existingCookieHeaderValue) {
					req.headers.cookie = jwtCookieHeaderValue;
				}

				const cookies = [
					jwtCookieHeaderValue,
					bSessionCookieHeaderValue,
				];

				res.setHeader("Set-Cookie", cookies);
			} else {
				if (document) {
					document.cookie = [`b-session=${jwt}`, `path=/`, `max-age=${BROWSING_SESSION_TOKEN_EXPIRY_SECONDS}`, `SameSite=Strict`, `Secure`].join("; ")
				}
			}

			const cookieAttributes: CookieAttributes = {
				...baseApolloCookieAttributes,
				expires: BROWSING_SESSION_TOKEN_EXPIRY_DAYS // NOTE: this argument uses days as its value
			};

			await app.$apolloHelpers.onLogin(jwt, undefined, cookieAttributes);
		}
	};

	const getAssignedAgent = async () => {
		// get an agent (either a specific one, or a random one)
		let result;

		try {
			result = await client.query<PublicBrowsingAgentUser>({
				query: PublicBrowsingAgentUserQuery,
			});
		} catch (e) {
			throw e;
		}

		const {publicBrowsingAgentUser} = result.data;

		await store.dispatch("setAssignedAgent", publicBrowsingAgentUser);
	};

	// get an existing viewer from the store, if any
	let { viewer } = store.state;

	if (!token) {
		const cookieTransformer = new Cookie(req ? req.headers.cookie : document.cookie);

		const bSessionValue = cookieTransformer.get("b-session");

		if (bSessionValue) {
			token = bSessionValue;
		}
	}

	if (token) {
		// decode token
		const tokenPayload = decodeToken(token);

		// if a user session
		if (!isBrowsingSessionPayload(tokenPayload)) {
			// extend session lifetime
			await app.$apolloHelpers.onLogin(token, undefined, {
				...baseApolloCookieAttributes,
				expires: USER_TOKEN_EXPIRY_DAYS,
			}, true);

			// if not currently signed in (e.g. app has just been initialized)
			if (!viewer) {
				// sign in (via apollo)
				let result;

				try {
					// retrieve the viewer from API
					result = await client.query<Viewer>({
						query: ViewerProfileQuery,
					});
				} catch (e) {
					$sentry?.captureException(e);
					redirect("/logout");
					return;
				}

				({viewer} = result.data);

				await store.dispatch("setViewer", viewer);
				await store.dispatch("setBrowsingSessionId", null);
			}

			// update analytics
			setUserAnalytics(viewer);

			if (store.getters.agent) {
				Object.assign(matomo, {
					agentVar: ["setCustomVariable", 2, "AgentId", store.getters.agent.oid],
				});
			}

			if (viewer.__typename === "ClientUser") {
				// client
				return;
			} else if (viewer.__typename === "AgentUser") {
				// is an agent. handle differently
				return;
			}
		} else {
			if (process.server) {
				// special logic on the server
				const existingCookieHeaderValue = req.headers.cookie;

				if (existingCookieHeaderValue) {
					// must do some cookie splicing, as cookie is changed mid-request
					const replacedCookieHeaderValue = existingCookieHeaderValue.replace(/(dot-love-api-token=)[^;]+(;)?/, `$1${token}$2;`);

					req.headers.cookie = replacedCookieHeaderValue;
				}

				const jwtCookieHeaderValue = cookie.serialize("dot-love-api-token", token, cookieOptions);

				res.setHeader("Set-Cookie", jwtCookieHeaderValue);
			}

			// extend session lifetime
			await app.$apolloHelpers.onLogin(token, undefined, {
				...baseApolloCookieAttributes,
				expires: BROWSING_SESSION_TOKEN_EXPIRY_DAYS,
			}, true);

			await store.dispatch("setBrowsingSessionId", tokenPayload.session_id);

			// run the following on the server;
			// suggesting a link was likely followed
			if (process.server) {
				await getAssignedAgent();

				let referralAgentUserId: string | undefined;
				let presented_by = query["presented-by"];

				if (Array.isArray(presented_by)) {
					const first = presented_by[0];

					if (first) {
						presented_by = first;
					}
				}

				if (typeof presented_by === "string") {
					referralAgentUserId = presented_by;
				}

				if (params) {
					// set the assigned agent to the presenting agent
					if (params.presenting_agent_user_id) {
						referralAgentUserId = params.presenting_agent_user_id;
					} else if (route.name === "agents-slug" && params.slug) {
						referralAgentUserId = params.slug;
					}
				}

				const {assignedAgent} = store.state;

				if (assignedAgent && referralAgentUserId && assignedAgent.oid !== referralAgentUserId) {
					// new agent detected; get a new browsing session
					await getBrowsingSession();
					await getAssignedAgent();
				}
			}
		}
	} else {
		// if no token, acquire a browsing session
		await getBrowsingSession();
	}

	if (!store.state.assignedAgent) {
		await getAssignedAgent();
	}

	if (store.getters.agent) {
		Object.assign(matomo, {
			agentVar: ["setCustomVariable", 2, "AgentId", store.getters.agent.oid],
		});
	}

	if (viewer?.__typename === "BasicUser") {
		if (!route.path.includes("/onboarding")) {
			// needs onboarding. redirect here
			const redirectTo = route.fullPath;
			const queryObject: {[key: string]: string} = {};

			if (redirectTo && redirectTo !== "/") {
				queryObject.redirect = redirectTo;
			}

			redirect("/onboarding", queryObject);
		}
	} else {
		setUserAnalytics(null);
	}
};

export default checkAuthMiddleware;
