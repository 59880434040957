// tslint:disable-next-line: no-implicit-dependencies
import { Plugin } from "@nuxt/types";

const isMobilePlugin: Plugin = ({store}) => {
	const onMQChanged = (event: MediaQueryListEvent | MediaQueryList) => store.commit("isMobile", !event.matches);

	const matchedMedia = window.matchMedia("(min-width: 480px)");

	matchedMedia.addListener(onMQChanged);
	onMQChanged(matchedMedia);
};

export default isMobilePlugin;
