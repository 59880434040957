
import { InMemoryCache, IntrospectionFragmentMatcher, InMemoryCacheConfig } from "apollo-cache-inmemory";
import introspectionQueryResultData from "../apollo/fragmentTypes.json";
import { Context } from "@nuxt/types"; // tslint:disable-line:no-implicit-dependencies
import { ApolloClientOptions } from "apollo-client";
import { FetchOptions as HttpLinkOptions } from "apollo-link-http"; // tslint:disable-line: no-implicit-dependencies
import { OutgoingHttpHeaders } from "http";

export default (context: Context) => {
	const {req, $config}  = context;
	const {HOST_API_CLIENT, HOST_API_SERVER} = $config;
	const host = process.client ? HOST_API_CLIENT : HOST_API_SERVER;

	const cacheOptions: InMemoryCacheConfig = {
		fragmentMatcher: new IntrospectionFragmentMatcher({
			introspectionQueryResultData,
		}),
		dataIdFromObject: (object) => object.id || null,
		freezeResults: true,
	};

	const apolloClientConstructorOptions: Partial<ApolloClientOptions<any>> = {
		// see https://github.com/apollographql/apollo-client/pull/4543
		assumeImmutableResults: true,
		defaultOptions: {
			watchQuery: {
				// fetchPolicy: 'cache-and-network',
				errorPolicy: 'ignore',
			},
			query: {
				// fetchPolicy: 'network-only',
				errorPolicy: 'all',
			},
			mutate: {
				errorPolicy: 'all',
			},
		}
	};

	const headers: OutgoingHttpHeaders = {};

	const ip = context.route.query["_ip_override"];

	if (typeof ip === "string") {
		headers["vnd.realestate.love.ip-address-override"] = ip;
	}

	// forward SSR-based request headers
	if (req) {
		const {["x-forwarded-for"]: forwardedFor, forwarded, ["user-agent"]: userAgent, ["cloudfront-viewer-time-zone"]: cloudfrontViewerTimeZone} = req.headers;

		if (forwardedFor) {
			headers["X-Forwarded-For"] = forwardedFor;
		} else if (forwarded) {
			headers.forwarded = forwarded;
		}

		if (userAgent) {
			headers["User-Agent"] = userAgent;
		}

		if (cloudfrontViewerTimeZone) {
			headers["CloudFront-Viewer-Time-Zone"] = cloudfrontViewerTimeZone;
		}
	} else {
		if (process.client && window.Intl && "DateTimeFormat" in window.Intl) {
			const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

			if (timeZone) {
				headers["Time-Zone"] = timeZone;
			}
		}

		headers["User-Agent"] = navigator.userAgent;
	}

	// See https://www.apollographql.com/docs/link/links/http.html#options
	const httpLinkOptions: HttpLinkOptions = {
		credentials: "same-origin",
		headers,
	};

	return {
		apollo: apolloClientConstructorOptions,

		// required
		httpEndpoint: `${host}/graphql`,

		cache: new InMemoryCache(cacheOptions),

		// optional
		httpLinkOptions,
	};
};
