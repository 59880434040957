import Vue from "vue";

Vue.filter("smsHref", (phonenumber: string, message?: string): string => {
	let href = `sms:${phonenumber}`;

	if (message) {
		const ua = (global as any as Window).navigator ? window.navigator.userAgent.toLowerCase() : "";
		const separator = (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1 || ua.indexOf("macintosh") > -1) ? "&" : "?";

		href += separator;
		href += `body=${encodeURIComponent(message)}`;
	}

	return href;
});
