
// tslint:disable: no-shadowed-variable

import { Viewer_viewer as ViewerNode } from "../gql-typings/Viewer";
import { Agent_agent as AgentUserNode } from "../gql-typings/Agent";
// tslint:disable-next-line: no-implicit-dependencies
import { MutationTree, GetterTree, ActionTree, ActionContext } from "vuex";

import { ICoordinate, ICoordinateRegion, makeCoordinateRegionPOJO, ICoordinateSpan } from "../misc/mapkit-shim";
import { AgentUserPlanTier } from "~/gql-typings/globalTypes";

import RecordGenericActorEventMutation from "@/apollo/RecordGenericActorEventMutation.graphql";
import RecordNodeActorEventMutation from "@/apollo/RecordNodeActorEventMutation.graphql";
import RecordListingSearchSearchActorEventMutation from "@/apollo/RecordListingSearchSearchActorEventMutation.graphql";
import RecordListingListItemsAddActorEventMutation from "@/apollo/RecordListingListItemsAddActorEventMutation.graphql";
import RecordListingListItemsRemoveActorEventMutation from "@/apollo/RecordListingListItemsRemoveActorEventMutation.graphql";
import { RecordGenericActorEventMutationVariables } from "~/gql-typings/RecordGenericActorEventMutation";
import { RecordNodeActorEventMutationVariables } from "~/gql-typings/RecordNodeActorEventMutation";
import { RecordListingListItemsAddActorEventMutationVariables } from "~/gql-typings/RecordListingListItemsAddActorEventMutation";
import { RecordListingListItemsRemoveActorEventMutationVariables } from "~/gql-typings/RecordListingListItemsRemoveActorEventMutation";

//ListingListItemsAddActorEventInput
import { RecordListingSearchSearchActorEventMutationVariables } from "~/gql-typings/RecordListingSearchSearchActorEventMutation";
import { ActorEventType } from "~/gql-typings/globalTypes";

const DEFAULT_MAP_SPAN: ICoordinateSpan = {
	latitudeDelta: 0.025,
	longitudeDelta: 0.025,
};

// Toronto - Trinity Bellwoods
const DEFAULT_LAT_LON: ICoordinate = {
	latitude: 43.647_778,
	longitude: -79.414_167,
};

const DEFAULT_COORDINATE_REGION = makeCoordinateRegionPOJO(DEFAULT_LAT_LON, DEFAULT_MAP_SPAN);

export interface RootState {
	redirect: string | null;
	viewer: ViewerNode | null;
	browsingSessionId: string | null;
	assignedAgent: AgentUserNode | null;
	lastMapCoordinateRegion: ICoordinateRegion;
	isMobile: boolean;
	canShare: boolean;
	isDarkMode: boolean;
	agentBarSeenFor: string | null;
	mapkitLoaded: boolean;
}

export const state = (): RootState => ({
	redirect: null,
	viewer: null,
	browsingSessionId: null,
	assignedAgent: null,
	lastMapCoordinateRegion: DEFAULT_COORDINATE_REGION,
	isMobile: true,
	canShare: false,
	isDarkMode: false,
	agentBarSeenFor: null,
	mapkitLoaded: false,
});

export const mutations: MutationTree<RootState> = {
	redirect(state, redirect: RootState["redirect"]) {
		state.redirect = redirect;
	},

	viewer(state, viewer: RootState["viewer"]) {
		state.viewer = viewer;

		if (viewer?.__typename === "ClientUser") {
			state.assignedAgent = null;
		}
	},

	browsingSessionId(state, browsingSessionId: RootState["browsingSessionId"]) {
		state.browsingSessionId = browsingSessionId;
	},

	assignedAgent(state, agent: RootState["assignedAgent"]) {
		state.assignedAgent = agent;
	},

	setLastMapCoordinateRegion(state, region: ICoordinateRegion) {
		state.lastMapCoordinateRegion = region;
	},

	isMobile(state, isMobile: boolean) {
		state.isMobile = isMobile;
	},

	canShare(state, canShare: boolean) {
		state.canShare = canShare;
	},

	isDarkMode(state, isDarkMode: boolean) {
		state.isDarkMode = isDarkMode;
	},

	setSeenAgentForAgentBar(state, agent_id: string | null) {
		state.agentBarSeenFor = agent_id;
	},

	setMapkitLoaded(state, loaded: boolean) {
		state.mapkitLoaded = loaded;
	}
};

export const getters: GetterTree<RootState, null> = {
	viewerAgent(state): AgentUserNode | null {
		const {viewer} = state;

		if (viewer) {
			if (viewer.__typename === "ClientUser") {
				return viewer.agent;
			} else if (viewer.__typename === "AgentUser") {
				return viewer;
			}
		}

		return null;
	},

	agent(state, getters): AgentUserNode | null {
		return getters.viewerAgent || state.assignedAgent;
	},

	showAgentBar(state, getters) {
		return !state.isMobile || state.agentBarSeenFor !== getters.agent?.id;
	},

	arePremiumFeaturesEnabled(state) {
		// if a agent user
		if (state.viewer?.__typename === "AgentUser") {
			return state.viewer.subscriberTier === AgentUserPlanTier.PREMIUM;
		} else {
			return true;
		}
	}
};

export interface Actions<S, R> extends ActionTree<S, R> {
	reset(context: ActionContext<S, R>): void;
	setViewer(context: ActionContext<S, R>, viewer: RootState["viewer"]): void;
	setAssignedAgent(context: ActionContext<S, R>, agent: RootState["assignedAgent"]): void;
	setRedirect(context: ActionContext<S, R>, payload: string | null): void;
	getAndClearRedirect(context: ActionContext<S, R>): string | null;
	storeSearchCoordinateRegion(context: ActionContext<S, R>, payload: ICoordinateRegion): void;
	hideMobileBanner(context: ActionContext<S, R>): void;
	setSeenAgentForAgentBar(context: ActionContext<S, R>): void;
	markMapkitAsLoaded(context: ActionContext<S, R>): void;
}

export type RootActions = Actions<RootState, null>;

export const actions: RootActions = {
	async reset({dispatch}) {
		return Promise.all([
			dispatch("setViewer", null),
			dispatch("setBrowsingSessionId", null),
			dispatch("setAssignedAgent", null),
			dispatch("storeSearchCoordinateRegion", DEFAULT_COORDINATE_REGION)
		]);
	},

	setRedirect({commit}, payload: string | null) {
		commit("redirect", payload);
	},

	getAndClearRedirect({commit, state}) {
		const {redirect} = state;

		commit("redirect", null);

		return redirect;
	},

	async setViewer({commit, dispatch}, payload: ViewerNode | null) {
		commit("viewer", payload);
		await dispatch("updateDefaultSearchCoordinateFromViewerOrSessionAgent");
	},

	setBrowsingSessionId({commit}, payload: string | null) {
		commit("browsingSessionId", payload);
	},

	async setAssignedAgent({commit, dispatch}, payload) {
		commit("assignedAgent", payload);
		await dispatch("updateDefaultSearchCoordinateFromViewerOrSessionAgent");
	},

	updateDefaultSearchCoordinateFromViewerOrSessionAgent({dispatch, state}) {
		// in order to support search location updating upon agent user switching,
		// the agent's ID will need to be stored in local storage
		// otherwise, there's no way to check the previous agent user

		const {viewer, assignedAgent, lastMapCoordinateRegion} = state;
		const {center: lastMapCoordinate} = lastMapCoordinateRegion;

		// check if the existing `lastMapCoordinateRegion` is equivalent to the `DEFAULT_COORDINATE_REGION`
		// if it's identical, we can assign a new coordinate
		if (!(
			lastMapCoordinate.latitude.toFixed(3) === DEFAULT_LAT_LON.latitude.toFixed(3) &&
			lastMapCoordinate.longitude.toFixed(3) === DEFAULT_LAT_LON.longitude.toFixed(3)
		)) {
			return;
		}

		let agentUser: AgentUserNode;

		if (viewer) {
			if (viewer?.__typename === "ClientUser") {
				agentUser = viewer.agent;
			} else if (viewer?.__typename === "AgentUser") {
				agentUser = viewer;
			} else {
				return;
			}
		} else if (assignedAgent) {
			agentUser = assignedAgent;
		} else {
			return;
		}

		const {defaultSearchPoint} = agentUser;

		const coordinateRegion: ICoordinateRegion = {
			center: {
				latitude: defaultSearchPoint.lat,
				longitude: defaultSearchPoint.lon,
			},
			span: DEFAULT_MAP_SPAN,
		};

		dispatch("storeSearchCoordinateRegion", coordinateRegion);
	},

	storeSearchCoordinateRegion({commit}, payload: ICoordinateRegion) {
		const fixed = makeCoordinateRegionPOJO(payload.center, payload.span);

		commit("setLastMapCoordinateRegion", fixed);
	},

	hideMobileBanner({commit}) {
		commit("hideMobileBanner");
	},

	setSeenAgentForAgentBar({commit, getters}) {
		commit("setSeenAgentForAgentBar", getters.agent?.id);
	},

	markMapkitAsLoaded({commit}) {
		commit("setMapkitLoaded", true);
	},

	recordGenericActorEvent(_state, payload: {type: ActorEventType}) {
		const client = this.app.apolloProvider.defaultClient;

		return client.mutate<any, RecordGenericActorEventMutationVariables>({
			mutation: RecordGenericActorEventMutation,
			variables: {
				input: {
					type: payload.type,
				},
			},
		});
	},

	recordNodeActorEvent(_state, payload: {type: ActorEventType, resource_id: string}) {
		const client = this.app.apolloProvider.defaultClient;

		return client.mutate<any, RecordNodeActorEventMutationVariables>({
			mutation: RecordNodeActorEventMutation,
			variables: {
				input: {
					type: payload.type,
					resourceId: payload.resource_id,
				},
			},
		});
	},

	recordListingSearchSearchActorEvent(_state, payload: RecordListingSearchSearchActorEventMutationVariables["input"]) {
		const client = this.app.apolloProvider.defaultClient;

		return client.mutate<any, RecordListingSearchSearchActorEventMutationVariables>({
			mutation: RecordListingSearchSearchActorEventMutation,
			variables: {
				input: payload,
			},
		});
	},

	recordListingListItemsAddActorEvent(_state, payload: {listing_list_id: string, listing_ids: string[]}) {
		const client = this.app.apolloProvider.defaultClient;

		return client.mutate<any, RecordListingListItemsAddActorEventMutationVariables>({
			mutation: RecordListingListItemsAddActorEventMutation,
			variables: {
				input: {
					listingList: payload.listing_list_id,
					listings: payload.listing_ids,
				},
			},
		});
	},

	recordListingListItemsRemoveActorEvent(_state, payload: {listing_list_id: string, item_ids: string[]}) {
		const client = this.app.apolloProvider.defaultClient;

		return client.mutate<any, RecordListingListItemsRemoveActorEventMutationVariables>({
			mutation: RecordListingListItemsRemoveActorEventMutation,
			variables: {
				input: {
					listingList: payload.listing_list_id,
					items: payload.item_ids,
				},
			},
		});
	},
};
