// tslint:disable-next-line: no-implicit-dependencies
import { Plugin } from "@nuxt/types";
import { Store } from "vuex/types/index";
import { RootState } from "~/store";

const setUserPlugin: Plugin = ({$sentryReady, store: _store, route}) => {
	const store = _store as Store<RootState>;

	const viewer = store.state.viewer;

	$sentryReady?.().then(sentry => {
		sentry.setUser(viewer ? {
			id: viewer.oid,
			type: viewer.__typename,
		} : null);
	});

	if (route.meta) {
		route.meta.matomo ||= {};

		Object.assign(route.meta.matomo, {
			userId: ["setUserId", viewer ? viewer.oid : ""],
			userTypeVar: ["setCustomVariable", 1, "UserType", viewer ? viewer.__typename : ""],
		});

		if (store.getters.agent) {
			Object.assign(route.meta.matomo, {
				agentVar: ["setCustomVariable", 2, "AgentId", store.getters.agent.oid],
			});
		}
	}
};

export default setUserPlugin;
