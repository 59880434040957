import Vue from "vue";
import VueMapkit from "../components/vue-mapkit";

// tslint:disable-next-line: no-implicit-dependencies
import { Context } from "@nuxt/types";

// this awaits mapkit initialization before showing the app.
// this may be done better by deferring until a mapkit vue component is shown
export default (context: Context) => {
	const { MAPKIT_TOKEN } = context.$config;

	Vue.use(VueMapkit, {
		authorizationCallback(done: any) {
			// provide mapkit jwt here
			done(MAPKIT_TOKEN)
		},
	});
};
