import createPersistedState from "vuex-persistedstate";
// tslint:disable-next-line: no-implicit-dependencies
import { Context } from "@nuxt/types";

export default ({store}: Context) => {
	createPersistedState({
		key: "vuex",
		paths: [
			"lastMapCoordinateRegion",
			"agentBarSeenFor",
		],
	})(store);
};
