import Vue from "vue";

Vue.filter("currency", (value: string): string => {
	const numericValue = parseFloat(value);
	let precision = 2;

	if (numericValue.toFixed(2) === Math.round(numericValue).toFixed(2)) {
		precision = 0;
	}

	const formatter = new Intl.NumberFormat("en-CA", {
		style: "currency",
		currency: "CAD",
		currencyDisplay: "symbol",
		useGrouping: true,
		minimumFractionDigits: precision,
		maximumFractionDigits: precision,
	});

	const result = formatter.format(numericValue).replace(/[^0-9,\.$]/g, "");

	return result;
});
