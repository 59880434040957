/// <reference path="../declarations/mapkit/index.d.ts" />

interface ICoordinate {
	latitude: number;
	longitude: number;
}

interface ICoordinateSpan {
	latitudeDelta: number;
	longitudeDelta: number;
}

interface ICoordinateRegion {
	center: ICoordinate;
	span: ICoordinateSpan;
}

const makeCoordinatePOJO = (latitude: number, longitude: number): ICoordinate => ({latitude, longitude});

const makeCoordinateSpanPOJO = (latitudeDelta: number, longitudeDelta: number): ICoordinateSpan => ({latitudeDelta, longitudeDelta});

const makeCoordinateRegionPOJO = (center: ICoordinate, span: ICoordinateSpan): ICoordinateRegion => ({center, span});

export {
	ICoordinate,
	ICoordinateSpan,
	ICoordinateRegion,
	makeCoordinatePOJO,
	makeCoordinateSpanPOJO,
	makeCoordinateRegionPOJO,
};
