// tslint:disable-next-line: no-implicit-dependencies
import { Context, Plugin } from "@nuxt/types";

const swPlugin: Plugin = async (context: Context) => {

	if (!("serviceWorker" in navigator)) {
		return;
	}

	try {
		await navigator.serviceWorker.register("/sw.js");
	} catch (e) {
		console.error(e);
		context.$sentry?.captureException(e);
	}
};

export default swPlugin;
