// tslint:disable-next-line: no-implicit-dependencies
import { Plugin } from "@nuxt/types";

const isDarkModePlugin: Plugin = ({store}) => {
	const onMQChanged = (_event: MediaQueryListEvent | MediaQueryList) => store.commit("isDarkMode", false/*event.matches*/);

	const matchedMedia = window.matchMedia("(prefers-color-scheme: dark)");

	matchedMedia.addListener(onMQChanged);
	onMQChanged(matchedMedia);
};

export default isDarkModePlugin;
