// tslint:disable-next-line: no-implicit-dependencies
import { Middleware } from "@nuxt/types";
// tslint:disable-next-line: no-implicit-dependencies
import { Store } from "vuex";
import { RootState } from "~/store";

const onboardingCheckMiddleware: Middleware = async (context) => {
	const { redirect, store: _store } = context;
	const store = _store as Store<RootState>;
	const { viewer } = store.state;

	// redirect away from onboarding if not logged in
	if (!viewer) {
		redirect("/login");
	} else if (viewer.__typename !== "BasicUser") {
		redirect("/home");
	}
};

export default onboardingCheckMiddleware;
