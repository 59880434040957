<template>
  <div class="__nuxt-error-page">
    <div class="error">
      <div class="title">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NuxtError',
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  computed: {
    statusCode () {
      return (this.error && this.error.statusCode) || 500
    },
    message () {
      return this.error.message || '<%= messages.client_error %>'
    }
  },
  head () {
    return {
      title: this.message,
    }
  }
}
</script>

<style>
.__nuxt-error-page {
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.__nuxt-error-page .error {
  max-width: 450px;
}

.__nuxt-error-page .title {
  font-size: 1.5rem;
  margin-top: 15px;
  margin-bottom: 8px;
}

.__nuxt-error-page .description {
  line-height: 21px;
  margin-bottom: 10px;
}
</style>
