import Vue from "vue";
import { unit as mathUnit } from "mathjs";

Vue.filter("unitConvert", (value: string, from: string, to: string): string => {
	const numericValue = parseFloat(value);

	const toUnit = mathUnit(numericValue, from).to(to);
	const toValue = toUnit.toNumber(to);

	if (toValue.toFixed(2) === Math.round(toValue).toFixed(2)) {
		return toValue.toFixed(0);
	} else {
		return toValue.toFixed(2);
	}
});
