/// <reference path="../declarations/mapkit/index.d.ts" />
import Vue, { VueConstructor } from "vue";

interface MapkitInitEvent {
	status: string;
}

let initialized = false;

const mapOptions = {
    authorizationCallback(_done: any) {
        throw new Error('authorizationCallback not implemented')
    },
}

export function setMapOptions(options = {}) {
    Object.assign(mapOptions, options)
}

export function loadMapkit(script_src: string) {
    return new Promise((resolve, reject) => {
        if (window.mapkit) {
            return resolve(window.mapkit);
        }

		const existingScript = (document.querySelector(`script[src="${script_src}"]`) as HTMLScriptElement | null);
		const script = existingScript || document.createElement('script');

		const {onload, onerror} = script;

        script.onload = (event) => {
			onload?.bind(script)(event);
			resolve(window.mapkit);
		};

        script.onerror = error => {
			onerror?.bind(script)(error);
			reject(error);
		};

        document.head.appendChild(script);

		if (!existingScript) {
        	script.src = script_src;
		}
    })
}

export async function init(script_src: string, options = {}) {
    await loadMapkit(script_src)

    if (initialized) {
		return true;
	}

	await new Promise((resolve, reject) => {
		const success = (event: MapkitInitEvent) => {
			window.mapkit.removeEventListener("configuration-change", success);

			switch (event.status) {
				case "Initialized":
					break;
				case "Refreshed":
					console.log("refreshed");
					// MapKit JS configuration updated.
					break;
				default:
					break;
			}

			resolve(event);
		};

		const error = (event: MapkitInitEvent) => {
			window.mapkit.removeEventListener("error", error);

			switch (event.status) {
				case "Unauthorized":
					console.log("Unauthorized");
					break;
				case "Too Many Requests":
					console.log("Too Many Requests");
					break;
				default:
					break;
			}

			reject(error);
		};

		window.mapkit.addEventListener("configuration-change", success);
		window.mapkit.addEventListener("error", error);

		window.mapkit.init({ ...mapOptions, ...options })
	});

	initialized = true
}

export default {
    install(_Vue: VueConstructor<Vue>, options = {}) {
        setMapOptions(options)
    },
}
