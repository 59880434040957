// tslint:disable-next-line: no-implicit-dependencies
import { Middleware } from "@nuxt/types";
// tslint:disable-next-line: no-implicit-dependencies
import { Store } from "vuex";

import { RootState } from "~/store";

const checkRedirectMiddleware: Middleware = async (context) => {
	const { req, store: _store, query/*, redirect, route*/ } = context;
	const store = _store as Store<RootState>;

	// If nuxt generate, pass this middleware
	if (process.server && !req) return;

	if (typeof query.redirect === "string") {
		await store.dispatch("setRedirect", query.redirect);

		/*const newQuery = {...query};
		delete newQuery.redirect;

		return redirect(route.path, newQuery);*/
	}
};

export default checkRedirectMiddleware;
