const middleware = {}

middleware['check-auth'] = require('../middleware/check-auth.ts')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['check-redirect'] = require('../middleware/check-redirect.ts')
middleware['check-redirect'] = middleware['check-redirect'].default || middleware['check-redirect']

middleware['onboarding'] = require('../middleware/onboarding.ts')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

export default middleware
