import Vue from "vue";

Vue.filter("formatDate", (value: string | Date, formatOptions: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "short",
	day: "numeric",
}): string => {
	const date = value instanceof Date ? value : new Date(value);

	return new Intl.DateTimeFormat("en-CA", formatOptions).format(new Date(date));
});
