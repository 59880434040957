// tslint:disable-next-line: no-implicit-dependencies
import { Plugin } from "@nuxt/types";

const canSharePlugin: Plugin = ({store}) => {
	const canShare = Boolean((navigator as any).share);

	store.commit("canShare", canShare);
};

export default canSharePlugin;
